import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, Subject } from "rxjs";

import { CourseMaterialsTitles } from "@app/shared/enums/course-materials-titles";
import { CourseStatsMaterials } from "@app/training/course-facade-group-page/view-models/CourseStatsMaterials";

export interface SelectedMenuItemData {
  material: CourseMaterialsTitles;
  course: CourseStatsMaterials;
  assignmentGroupIndex?: number;
  assignmentIndex?: number;
  noSelectEvent?: boolean;
}

export interface MaterialFinishedData {
  courseId: number;
  material: CourseMaterialsTitles;
}

export interface AssignmentCompletedData {
  courseId: number;
  assignmentsCompleted: number;
  assignmentsTotal: number;
  assignmentGroupIndex: number | null;
  completedAssignmentIndex: number | null;
}

@Injectable({
  providedIn: "root",
})
export class CourseFacadeGroupMenuService {
  private readonly _selectedItemChanged = new BehaviorSubject<SelectedMenuItemData | null>(null);
  private readonly _materialFinished = new Subject<MaterialFinishedData | null>();
  private readonly _assignmentsCompletedNumber = new Subject<AssignmentCompletedData | null>();

  private readonly _resetAssignments = new Subject<void>();
  private readonly _giveFeedback = new Subject<void>();
  private readonly _questionNumberChanged = new Subject<number>();
  private readonly _tabSwitch = new Subject<SelectedMenuItemData>();

  public get selectedMenuItemData$(): Observable<SelectedMenuItemData | null> {
    return this._selectedItemChanged.asObservable();
  }

  public setSelectedMenuItemData(item: SelectedMenuItemData) {
    this._selectedItemChanged.next(item);
  }

  public get assignmentsCompletedNumber$(): Observable<AssignmentCompletedData | null> {
    return this._assignmentsCompletedNumber.asObservable();
  }

  public setAssignmentsCompletedNumber(data: AssignmentCompletedData) {
    this._assignmentsCompletedNumber.next(data);
  }

  public get materialFinished$(): Observable<MaterialFinishedData | null> {
    return this._materialFinished.asObservable();
  }

  public setMaterialFinished(data: MaterialFinishedData) {
    this._materialFinished.next(data);
  }

  public get resetAssignments$(): Observable<void> {
    return this._resetAssignments.asObservable();
  }

  public onResetAssignments(): void {
    this._resetAssignments.next();
  }

  public get giveFeedback$(): Observable<void> {
    return this._giveFeedback.asObservable();
  }

  public onFeedback(): void {
    this._giveFeedback.next();
  }

  public get questionNumberChanged$(): Observable<number> {
    return this._questionNumberChanged.asObservable();
  }

  public onQuestionNumberChanged(number: number): void {
    this._questionNumberChanged.next(number);
  }

  public get tabSwitch$(): Observable<SelectedMenuItemData> {
    return this._tabSwitch.asObservable();
  }

  public onTabSwitch(data: SelectedMenuItemData) {
    this._tabSwitch.next(data);
  }
}
